import { districts } from '../pages/Flat/components/Form/constant'

export const cityList = [
  { value: 'kiev', text: 'Київ' },
  { value: 'lviv', text: 'Львів' },
]

export const getCityText = (city: string) => {
  return cityList.find(c => c.value === city)?.text
}

export const getStreetText = (address) => {
  if (!address) {
    return ''
  }
  return `${address.street}, ${address.streetNumber}`
}

export const getDistrictText = district => {
  const label = districts.find(d => d.value === district)?.label
  return label ? `${label}` : ''
}
