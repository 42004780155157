import { createAction, createSlice, PayloadAction, } from '@reduxjs/toolkit'

const prefix = 'pages/flats'

export const clearStore = createAction(`${prefix}/clearStore`)

const flatsSlice = createSlice({
  name: prefix,
  initialState: {
    isLoading: false as boolean,
    flatList: [] as any,
    flat: {} as any
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setFlatList: (state, { payload }: PayloadAction<any>) => {
      state.flatList = payload
    },
    setFlat: (state, { payload }: PayloadAction<any>) => {
      state.flat = payload
    },
  },
  extraReducers: {
    [clearStore.type]: () => ({
      isLoading: false,
      flatList: [],
      flat: {}
    }),
  },
})

// actions
export const { setIsLoading, setFlatList, setFlat } = flatsSlice.actions

export const getFlatList = createAction<{ city: string, publicId: string }>(`${prefix}/getFlatList`)
export const getFlat = createAction<{ flatId: string }>(`${prefix}/getFlat`,)
export const createFlat = createAction<any>(`${prefix}/createFlat`)
export const updateFlat = createAction<any>(`${prefix}/updateFlat`)
export const removeFlatToArchive = createAction<{ flat: any }>(`${prefix}/removeFlatToArchive`)
export const addFlatImages = createAction<{ files: FileList, callback: Function }>(`${prefix}/addFlatImages`)

export const flatsReducer = flatsSlice.reducer
