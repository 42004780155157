import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { getBookingListReq, pushNotificationContactReq, pushNotificationNotActualReq, removeBookingReq } from '../../../api/booking'

export function* getBookingListApi() {
  yield put(actions.setIsLoading(true))
  try {
    const { data } = yield getBookingListReq(0, 100)
    yield put(actions.setBookingList(data.bookings))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* removeBookingApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { bookingId } } = action
  try {
    yield removeBookingReq(bookingId)
    yield getBookingListApi()
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* sendNotificationContactApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { bookingId } } = action
  try {
    yield pushNotificationContactReq(bookingId)
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* sendNotificationNotActualApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { bookingId } } = action
  try {
    yield pushNotificationNotActualReq(bookingId)
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* watchBookingsApi() {
  yield takeLatest(actions.getBookingList.type, getBookingListApi)
  yield takeLatest(actions.removeBooking.type, removeBookingApi)
  yield takeLatest(actions.sendNotificationContact.type, sendNotificationContactApi)
  yield takeLatest(actions.sendNotificationNotActual.type, sendNotificationNotActualApi)
}
