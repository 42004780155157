export const pages = {
  login: '/login',
  register: '/register',
  logout: '/logout',
  flatList: '/',
  flatCreate: '/flats/create',
  flatEdit: '/flats/edit/:flatId',
  bookingList: '/bookings',
  archiveList: '/archives',
  hostList: '/hosts',
  settings: '/settings',
}

export const getFlatEditPage = (id: number) => `/flats/edit/${id}`
